import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    serviceArea: null,
  },
  mutations: {
    SET_SERVICE_AREA(state, area) {
      state.serviceArea = area;
    },
  },
  actions: {},
  modules: {},
});
