<template>
  <div id="app">
    <!-- <Hero />
    <Intro />
    <Areas />
    <SalesMarketing />
    <TrainingSimulation />
    <FieldService />
    <Enablement />
    <Collaboration />
    <Assessments />
    <Articles />
    <Video />
    <Team />
    <transition name="fade">
    </transition>
    <ContactForm />
    <Footer /> -->
  </div>
</template>

<script>
// import Hero from "./components/Hero.vue";
// import Footer from "./components/Footer.vue";
// import Intro from "./components/Intro.vue";
// import Areas from './components/Areas.vue';
// import SalesMarketing from "./components/SalesMarketing/index.vue";
// import TrainingSimulation from './components/TrainingSimulation/index.vue';
// import FieldService from './components/FieldService/index.vue';
// import Enablement from './components/Enablement.vue';
// import Collaboration from './components/Collaboration.vue';
// import Assessments from './components/Assessments.vue';
// import Articles from './components/Articles.vue';
// import Video from './components/Video.vue';
// import Team from './components/Team.vue';
// import ContactForm from './components/ContactForm.vue';





export default {
  name: "App",
  components: {
    // Hero,
    // Footer,
    // Intro,
    // Areas,
    // SalesMarketing,
    // TrainingSimulation,
    // FieldService,
    // Enablement,
    // Collaboration,
    // Assessments,
    // Articles,
    // Video,
    // Team,
    // ContactForm
  },
  data() {
    return {
      // showYammer: false
    }
  },
 mounted() {
    window.location.href = "https://siemens.com";
    // const myself = this;
    // document.addEventListener("scroll", function () {
    //   if (!myself.animated) {
    //     myself.animateBanner();
    //     myself.animated = true;
    //   }
    // });
    // const arrows = document.getElementsByClassName('vs-carousel__arrows');
    // const arrowLeftIcon = '<i class="icon-Arrow-left"></i>';
    // const arrowRightIcon = '<i class="icon-Arrow-right"></i>';

    // arrows.forEach((arrow) => {
    //   if (arrow.tagName === 'BUTTON') {
    //     if (arrow.className.includes('--left')) {
    //       arrow.innerHTML = arrowLeftIcon;
    //     } else {
    //       arrow.innerHTML = arrowRightIcon;
    //     }
    //   }
    //   // if (arrow.)
    // })
    // // this.checkYammer();
  },
  methods: {
    animateBanner() {
      const faders = document.querySelectorAll(".fade-in");
      const sliders = document.querySelectorAll(".slide-in");
      // const hideAfter = document.querySelectorAll(".hide-after");

      const appearOptions = {
        threshold: 0,
        rootMargin: "0px 0px -250px 0px",
      };

      const appearOnScroll = new IntersectionObserver(function (
        entries,
        appearOnScroll
      ) {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          } else {
            if (entry.target.classList.contains("btn")) {
              setTimeout(() => {
                entry.target.classList.add("appear");
              }, 1000);
            } else {
              entry.target.classList.add("appear");
            }

            if (entry.target.classList.contains("hide-after")) {
              setTimeout(() => {
                entry.target.style.display = "none";
              }, 5000)
            } 
            appearOnScroll.unobserve(entry.target);
          }
        });
      },
      appearOptions);

      faders.forEach((fader) => {
        appearOnScroll.observe(fader);
      });

      sliders.forEach((slider) => {
        appearOnScroll.observe(slider);
      });

      // hideAfter.forEach((slider) => {
      //   appearOnScroll.observe(slider);
      // });
    },
    // checkYammer() {
    //   document.addEventListener("yammerLoggedIn", () => {
    //     this.showYammer = true;
    //   });
    // }
  },
};
</script>
